
import Vue from "vue";
import API from "@/api/API";
import store from "@/store";
import { Dictionary } from "vue-router/types/router";
import tablePagination from "@/mixins/table-pagination";
import tableFilters from "@/mixins/table-filters";
import { parseArrayParams } from "@/services/helpers";
import { mask } from "vue-the-mask";

export default Vue.extend({
  name: "subdivisions",

  mixins: [tablePagination, tableFilters],

  directives: { mask },

  data: () => ({
    parseArrayParams,
    filter: {} as Dictionary<string | (string | null)[]>,
    headers: [] as Array<any>,
    items: [] as Array<any>,
    loading: false as boolean,
    showMenu: false,
    x: 0,
    y: 0,
    activeItemId: null,
    selects: {
      branches: [] as Array<any>,
      subdivisions: [] as Array<any>
    }
  }),

  async beforeRouteEnter(to, from, next) {
    try {
      const params = {
        filter: to.query,
        page: {
          number: to.params.page,
          size: 50
        }
      };
      const response = await API.subdivisions().getData(params);
      const subdivisions = await API.subdivisions().getList();
      const branches = await API.branches().getList();

      next(vm => {
        vm.setFilters(params.filter);
        vm.setServerResponse(response);
        vm.selects.subdivisions = subdivisions.items;
        vm.selects.branches = branches.items;
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  methods: {
    downloadFile(file: any) {
      const element = document.createElement("a");
      element.setAttribute("href", file.url || file);
      element.setAttribute("target", "_blank");
      element.click();
    },
    async loadData(): Promise<void> {
      this.loading = true;
      try {
        const response = await this.$API.subdivisions().getData({
          filter: this.parseArrayParams(this.filter),
          page: this.pagination,
          sort: this.getSortParams()
        });
        this.setServerResponse(response);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    async deleteRow(id: number): Promise<void> {
      this.loading = true;
      try {
        await this.$API.subdivisions().delete(id);
        await this.$store.dispatch(
          "alert/showSuccess",
          this.$t("global_alert.successful_removal")
        );
        await this.loadData();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    setFilters(filter: Dictionary<string | (string | null)[]>): void {
      const newFilters = this.getAvailableFilters(filter);
      if (Object.keys(newFilters).length) {
        this.showFilters = true;
      }

      this.filter = newFilters;
      this.changeActiveFilters();
    },
    setServerResponse({ items, headers }: any) {
      this.headers = [
        ...headers,
        {
          text: "",
          value: "actions",
          width: "100px",
          align: "center",
          sortable: false
        }
      ];

      this.items = items?.data;

      this.totalItems = items.total;
    },
    showContextMenu(e: any, item: any) {
      this.activeItemId = item.item.id;
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    }
  }
});
